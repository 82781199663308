<template>
  <div class="RuleModal">
      <b-modal id="rule-lottery" hide-footer>
      <template #modal-title>
        กติกา
      </template>
      <div class="d-block text-center">
        <div style="width: 100%; overflow-y: scroll;height: 80vh;" v-if="IMAGE_PATH">
          <img :src="IMAGE_PATH + 'rule_'+lot_type_id+'.png'" style="width: 100%">
        </div>
        <div v-html="advice" style="width: 100%; overflow-y: scroll;height: 80vh;" v-else/>
      </div>
      <b-button  class="btn btn btn-danger mt-3" block @click="$bvModal.hide('rule-lottery')">ฉันเข้าใจและยอมรับ</b-button>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      advice: state => state.lotteryModule.inquiryRoundInfomationByID.advice,
      lot_type_id: state => state.lotteryModule.inquiryRoundInfomationByID.lot_type_id
    })
  },
  data () {
    return {
      IMAGE_PATH: process.env.VUE_APP_LOTTO_IMAGE_PATH
    }
  }
}
</script>

<style>
.bg-dark-blue {
  background: rgba(31, 49, 84, 1);
}
.btn-dark-blue {
  background: rgba(31, 49, 84, 1);
  color: white;
}
</style>
