<template>
  <div class="content1">
    <div class="bar-back d-flex justify-content-between align-items-center">
      <div id="top"></div>
      <router-link to="/member/lottery" class="mr-auto" id="yingying"
        ><i class="fas fa-chevron-left"></i> ย้อนกลับ</router-link
      >
       <a v-b-modal.rule-lottery href="#" class="btn btn-outline-secondary btn-sm mr-1 text-dark" >กติกา</a>
      <!-- <a href="#" class="btn btn-outline-secondary btn-sm mr-1 text-dark" data-toggle="modal" data-target="#rule-lottery"
        >กติกา</a
      > -->
      <a
        href="#"
        class="btn btn-dark btn-sm btn-numpage d-none active"
        data-id="numpage_1"
        ><i class="fas fa-calculator"></i> แทงเลข</a
      >
    </div>
    <app-rule-lottery-modal />
  </div>
</template>

<script>
import ruleLotteryModal from '@/components/modals/RuleLotteryModal'
export default {
  components: {
    'app-rule-lottery-modal': ruleLotteryModal
  }
}
</script>

<style>
</style>
