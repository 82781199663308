<template>
  <div
    class="
      d-flex
      flex-row
      justify-content-between
      align-items-stretch
      p-1
      mt-1
      pr-2
      bg-white
      rounded
      shadow-sm
      flex-fill
      cart-item-lists
    "
    id="numpage_1"
  >
    <div class="d-flex flex-column flex-fill box__play" id="show_poy_list" :class="{ 'hide-tab': isHide }">
      <div id="hide-list-huay" :class="{ 'hide-tab2': isHide }" @click="hideTab(true)">
        <div class="btn-move btn-move-left mb-1">
          <span><i class="fas fa-chevron-circle-left"></i> ซ่อน</span>
        </div>
      </div>
      <div class="sidebar-huay d-flex flex-column flex-fill" id="sidebar-huay" :class="{ 'hide-tab3': isHide }">
        <button class="btn btn-light btn-sm btn-block number-sets triggerPoy" @click="$emit('openSet', true)">
          <i class="fas fa-list-ol"></i> ดึงโพย
        </button>
        <button
          :disabled="numberList.length === 0"
          class="btn btn-light btn-sm btn-block show-lists triggerPrice"
          @click="openPrice()"
        >
          <i class="fas fa-edit"></i> ใส่ราคา
        </button>
        <div class="py-1">
          <h5 class="title-huay mt-2"><i class="fas fa-receipt"></i> รายการแทง</h5>
        </div>
        <span class="bet_num_count"> {{ resultReport() }} รายการ</span>
        <div class="list-huay flex-fill">
          <ol class="num-huay" id="show_bet_num" v-for="(data, keys) in getNumberList" v-bind:key="keys">
            {{
              data.title
            }}
            <li v-for="(num, index) in data.list" v-bind:key="index">
              {{ num }}
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div class="sidebar-tanghuay box__play" id="page_tang">
      <div
        id="show-list-huay"
        class="d-flex justify-content-between"
        :class="{ 'show-tab': !isHide }"
        @click="hideTab(false)"
      >
        <span class="btn-move bet_num_count justify-content-center rounded">  {{ resultReport() }} รายการ </span>
        <div class="btn-move btn-move-right flex-fill">
          <span>แสดง <i class="fas fa-chevron-circle-right"></i></span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <nav>
            <div class="nav nav-tabs nav-pills nav-justified" id="nav-tab1" role="tablist">
              <a
                class="nav-item nav-link p-1"
                id="nav-keyboard-tab"
                data-toggle="tab"
                href="#nav-keyboard"
                role="tab"
                aria-controls="nav-keyboard"
                aria-selected="true"
                >กดเลขเอง</a
              >
              <a
                class="nav-item nav-link active p-1"
                id="nav-panghuay-tab"
                data-toggle="tab"
                href="#nav-panghuay"
                role="tab"
                aria-controls="nav-panghuay"
                aria-selected="false"
                >เลือกจากแผง</a
              >
            </div>
          </nav>
        </div>
      </div>
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade" id="nav-keyboard" role="tabpanel" aria-labelledby="nav-keyboard-tab">
          <div class="row px-2 row-btn-tanghuay setting__number">
            <div
              class="col-6 col-sm-6 col-md-4 col-lg-3 p-1"
              v-for="(item, index) in getInquiryRoundInfomation.my_price.filter(e => e.enable)"
              :key="index"
            >
              <button
                class="
                  btn btn-sm btn-block
                  h-100
                  d-flex
                  justify-content-between
                  align-items-center
                  btn-tanghuay
                  bet_two
                "
                v-bind:class="[
                  listSelector.find(e => e.index === item.index) ? 'active' : '',
                  item.nlen === 2 ? 'btn-outline-blue' : item.nlen === 1 ? 'btn-outline-green' : 'btn-outline-red'
                ]"
                id="teng_bon_3"
                @click="selectedByIndex(item.index)"
              >
                <div
                  class="bg-primary text-white btn-width"
                  v-bind:class="item.nlen === 2 ? 'bg-primary' : item.nlen === 1 ? 'bg-success' : 'bg-danger'"
                >
                  <i>
                    {{ item.title }}
                  </i>
                </div>
                <div>{{ item.price }}</div>
              </button>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-3 p-1">
              <button
                class="
                  btn btn-sm btn-block
                  h-100
                  d-flex
                  justify-content-between
                  align-items-center
                  btn-tanghuay
                  bet_two
                "
                v-bind:class="['btn-outline-blue', reserveTwoNumber ? 'active' : '']"
                id="teng_bon_3"
                @click="setReverseNumber(true)"
              >
                <div class="bg-primary text-white btn-width" v-bind:class="'bg-primary'">
                  <i> สองตัวกลับ </i>
                </div>
                <div class="text-center"><i class="fas fa-random"></i></div>
              </button>
            </div>
            <div class="col-6 col-sm-6 col-md-4 col-lg-3 p-1">
              <button
                class="
                  btn btn-sm btn-block
                  h-100
                  d-flex
                  justify-content-between
                  align-items-center
                  btn-tanghuay
                  bet_two
                "
                v-bind:class="['btn-outline-red', reserveThreeNumber ? 'active' : '']"
                id="teng_bon_3"
                @click="setReverseNumber(false)"
              >
                <div class="bg-primary text-white btn-width" v-bind:class="'bg-danger'">
                  <i> สามตัวกลับ </i>
                </div>
                <div class="text-center"><i class="fas fa-random"></i></div>
              </button>
            </div>
          </div>
          <div
            class="bg-option p-1 pb-0 mt-1 rounded box__two-option box__play setting__number"
            v-if="listSelector.find(e => e.nlen === 2)"
          >
            <i class="fas fa-bars"></i> ตัวเลือกเพิ่มเติม
            <div class="d-flex flex-lg-row justify-content-around align-content-stretch flex-wrap">
              <div class="flex-fill">
                <div class="bg-btn">
                  <button
                    class="btn btn-sm btn-block h-100 bet_two"
                    id="option_2_19"
                    @click="setSubType('19 ประตู')"
                    :class="subType == '19 ประตู' ? 'btn-dark' : 'btn-danger'"
                  >
                    19 ประตู
                  </button>
                </div>
              </div>
              <div class="flex-fill">
                <div class="bg-btn">
                  <button
                    class="btn btn-danger btn-sm btn-block h-100 bet_two"
                    id="option_2_ble"
                    @click="doublePushNumber()"
                  >
                    เลขเบิ้ล
                  </button>
                </div>
              </div>
              <div class="flex-fill">
                <div class="bg-btn">
                  <button
                    class="btn btn-primary btn-sm btn-block h-100 bet_two"
                    id="option_2_roodnha"
                    @click="setSubType('รูดหน้า')"
                    :class="subType == 'รูดหน้า' ? 'btn-dark' : 'btn-primary'"
                  >
                    รูดหน้า
                  </button>
                </div>
              </div>
              <div class="flex-fill">
                <div class="bg-btn">
                  <button
                    class="btn btn-sm btn-block h-100 bet_two"
                    id="option_2_roodlung"
                    @click="setSubType('รูดหลัง')"
                    :class="subType == 'รูดหลัง' ? 'btn-dark' : 'btn-primary'"
                  >
                    รูดหลัง
                  </button>
                </div>
              </div>
              <div class="flex-fill">
                <div class="bg-btn">
                  <button
                    class="btn btn-info btn-sm btn-block h-100 bet_two"
                    id="option_2_low"
                    @click="lowOrHighPushNumber(false)"
                  >
                    สองตัวต่ำ
                  </button>
                </div>
              </div>
              <div class="flex-fill">
                <div class="bg-btn">
                  <button
                    class="btn btn-info btn-sm btn-block h-100 bet_two"
                    id="option_2_high"
                    @click="lowOrHighPushNumber(true)"
                  >
                    สองตัวสูง
                  </button>
                </div>
              </div>
              <div class="flex-fill">
                <div class="bg-btn">
                  <button
                    class="btn btn-success btn-sm btn-block h-100 bet_two"
                    id="option_2_odd"
                    @click="oddOrEvenNumber(false)"
                  >
                    สองตัวคี่
                  </button>
                </div>
              </div>
              <div class="flex-fill">
                <div class="bg-btn">
                  <button
                    class="btn btn-success btn-sm btn-block h-100 bet_two"
                    id="option_2_even"
                    @click="oddOrEvenNumber(true)"
                  >
                    สองตัวคุ่
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100 bg-light px-1 pb-1 text-center border my-1" v-if="listSelector.length">
            <p class="border-bottom mb-1">
              <small>รายการที่เลือก</small>
            </p>
            <div id="resultoption">
              <span
                v-for="(item, index) in listSelector"
                :key="index"
                class="badge font-weight-light m-1"
                v-bind:class="item.nlen === 3 ? 'badge-danger' : item.nlen === 2 ? 'badge-primary' : 'badge-success'"
                id="teng_bon_3_label"
                >{{ item.title }}</span
              >
              <span
                v-if="subType"
                class="badge font-weight-light"
                v-bind:class="'badge-secondary'"
                id="teng_bon_3_label"
                >{{ subType }}</span
              >
              <span
                v-if="reserveThreeNumber"
                class="badge font-weight-light"
                v-bind:class="'badge-danger'"
                id="teng_bon_3_label"
                >สามตัวกลับ</span
              >
              <span
                v-if="reserveTwoNumber"
                class="badge font-weight-light"
                v-bind:class="'badge-primary'"
                id="teng_bon_3_label"
                >สองตัวกลับ</span
              >
            </div>
          </div>
          <div class="group__keyboard" style="" v-if="listSelector.length > 0">
            <div class="num-pad box__show-number">
              <h4 class="text-center">ระบุตัวเลข</h4>
              <div class="label-number lists" v-if="subType">
                <div id="bet_num">
                  <label class="number"> {{ subTypeNum }}</label>
                </div>
              </div>
              <div class="label-number lists" v-else>
                <div id="bet_num" v-if="number.split('').length > 0">
                  <label class="number" v-for="(item, index) in type" :key="index">{{
                    number.split('')[index] ? number.split('')[index] : ' '
                  }}</label>
                </div>
                <div id="bet_num" v-else>
                  <label class="number" v-for="index in type" :key="index"> </label>
                </div>
              </div>
              <div class="key-pad box__keyboard">
                <div class="row p-2">
                  <div
                    class="col-3"
                    :key="index"
                    v-for="(number, index) in [
                      1,
                      2,
                      3,
                      'delete',
                      4,
                      5,
                      6,
                      'cancel',
                      7,
                      8,
                      9,
                      'clear',
                      null,
                      0,
                      null,
                      undefined
                    ]"
                  >
                    <button
                      v-if="number === 'cancel'"
                      class="btn btn-secondary btn-block btn-cancel warning btn-cancle-last-add-num"
                      @click="removeLast()"
                    >
                      <span>ยกเลิก</span><span>ล่าสุด</span>
                    </button>
                    <button
                      v-else-if="number === 'clear'"
                      class="btn btn-dark btn-block btn-reset"
                      @click="clearList()"
                    >
                      <span>ล้าง</span><span>ข้อมูล</span>
                    </button>
                    <button
                      v-else-if="number === 'delete'"
                      class="btn btn-primary btn-block"
                      data-id="delete"
                      @click="deleteLast()"
                    >
                      <i class="fas fa-backspace"></i>
                    </button>
                    <div v-else-if="number === undefined"></div>
                    <button
                      v-else
                      :class="number === null ? 'btn btn-blank' : 'btn btn-outline-primary btn-block'"
                      :disabled="number === null ? true : false"
                      :data-id="number"
                      @click="subType ? setPushNumberSetRoot(number) : pushNumber(number)"
                    >
                      {{ number }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade show active" id="nav-panghuay" role="tabpanel" aria-labelledby="nav-panghuay-tab">
          <div class="w-100 p-1">
            <ul class="nav nav-pills nav-justified mb-2" id="number-tab" role="tablist">
              <li class="nav-item" @click="tabMode = 1">
                <a
                  class="nav-link p-1 py-3 active btn-outline-red"
                  id="pills-3-tab"
                  data-toggle="pill"
                  href="#pills-3"
                  role="tab"
                  aria-controls="pills-3"
                  aria-selected="true"
                  >สามตัว</a
                >
              </li>
              <li class="nav-item" @click="tabMode = 2">
                <a
                  class="nav-link p-1 py-3 btn-outline-blue"
                  id="pills-2-tab"
                  data-toggle="pill"
                  href="#pills-2"
                  role="tab"
                  aria-controls="pills-2"
                  aria-selected="false"
                  >สองตัว</a
                >
              </li>
              <li class="nav-item" @click="tabMode = 3">
                <a
                  class="nav-link p-1 py-3 btn-outline-green"
                  id="pills-run-tab"
                  data-toggle="pill"
                  href="#pills-run"
                  role="tab"
                  aria-controls="pills-run"
                  aria-selected="false"
                  >เลขวิ่ง</a
                >
              </li>
            </ul>
            <div class="w-100 limitnum" style="display: none">
              <div class="row mb-2 py-3 mx-0 bg-dark rounded">
                <div class="col pr-1">
                  <font color="#FFCC00"
                    ><b
                      ><i class="fas fa-exclamation-circle"></i> สีเหลือง =
                      เลขนี้มีคนแทงเยอะอัตราจ่ายอาจมีการเปลี่ยนแปลง<b></b></b
                  ></font>
                </div>
              </div>
            </div>
            <b
              ><b>
                <div class="tab-content" id="pills-tabContent-number">
                  <div class="tab-pane fade show active" id="pills-3" role="tabpanel" aria-labelledby="pills-3-tab">
                    <div class="row-btn-tanghuay">
                      <button
                        class="btn btn-grey btn-block btn-sm btn-panghuay py-2"
                        :class="{ active: reverseThreeTab }"
                        id="shuffle_3"
                        @click="reverseThreeTab = !reverseThreeTab"
                      >
                        <i class="fas fa-random"></i> กลับตัวเลข
                      </button>
                    </div>
                    <div class="row m-0">
                      <div
                        class="col-6 p-1 size-button"
                        v-for="(item, index) in getInquiryRoundInfomation.my_price.filter(
                          e => e.nlen === 3 && e.enable
                        )"
                        :key="index"
                      >
                        <div class="row-btn-tanghuay">
                          <button
                            class="
                              btn btn-outline-red btn-sm btn-block
                              h-100
                              d-flex
                              justify-content-between
                              align-items-center
                              btn-panghuay
                              bet_three
                            "
                            v-bind:class="[
                              listSelector.find(e => e.index === item.index) ? 'active' : '',
                              item.nlen === 2
                                ? 'btn-outline-blue'
                                : item.nlen === 1
                                ? 'btn-outline-green'
                                : 'btn-outline-red'
                            ]"
                            id="teng_bon_3"
                            @click="selectedByIndex(item.index)"
                          >
                            <div
                              class="bg-primary text-white btn-width"
                              v-bind:class="
                                item.nlen === 2 ? 'bg-primary' : item.nlen === 1 ? 'bg-success' : 'bg-danger'
                              "
                            >
                              <i>
                                {{ item.title }}
                              </i>
                            </div>
                            <div class="text-center mx-1">{{ item.price }}</div>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div id="samtua">
                      <div
                        v-bind:class="[listSelector.filter(v => v.nlen === 3).length > 0 ? '' : 'overlay-disable']"
                        id="oversamtua"
                      ></div>
                      <ul class="nav nav-pills mb-3 justify-content-center" id="numlist-tab1" role="tablist">
                        <li class="nav-item flex-fill text-center">
                          <a
                            class="nav-link border m-1 px-1 txt-num active"
                            id="tab-000"
                            data-toggle="pill"
                            href="#pills-000"
                            role="tab"
                            aria-controls="pills-000"
                            aria-selected="true"
                            >000</a
                          >
                        </li>
                        <li class="nav-item flex-fill text-center">
                          <a
                            class="nav-link border m-1 px-1 txt-num"
                            id="tab-100"
                            data-toggle="pill"
                            href="#pills-100"
                            role="tab"
                            aria-controls="pills-100"
                            aria-selected="false"
                            >100</a
                          >
                        </li>
                        <li class="nav-item flex-fill text-center">
                          <a
                            class="nav-link border m-1 px-1 txt-num"
                            id="tab-200"
                            data-toggle="pill"
                            href="#pills-200"
                            role="tab"
                            aria-controls="pills-200"
                            aria-selected="false"
                            >200</a
                          >
                        </li>
                        <li class="nav-item flex-fill text-center">
                          <a
                            class="nav-link border m-1 px-1 txt-num"
                            id="tab-300"
                            data-toggle="pill"
                            href="#pills-300"
                            role="tab"
                            aria-controls="pills-300"
                            aria-selected="false"
                            >300</a
                          >
                        </li>
                        <li class="nav-item flex-fill text-center">
                          <a
                            class="nav-link border m-1 px-1 txt-num"
                            id="tab-400"
                            data-toggle="pill"
                            href="#pills-400"
                            role="tab"
                            aria-controls="pills-400"
                            aria-selected="false"
                            >400</a
                          >
                        </li>
                        <li class="nav-item flex-fill text-center">
                          <a
                            class="nav-link border m-1 px-1 txt-num"
                            id="tab-500"
                            data-toggle="pill"
                            href="#pills-500"
                            role="tab"
                            aria-controls="pills-500"
                            aria-selected="false"
                            >500</a
                          >
                        </li>
                        <li class="nav-item flex-fill text-center">
                          <a
                            class="nav-link border m-1 px-1 txt-num"
                            id="tab-600"
                            data-toggle="pill"
                            href="#pills-600"
                            role="tab"
                            aria-controls="pills-600"
                            aria-selected="false"
                            >600</a
                          >
                        </li>
                        <li class="nav-item flex-fill text-center">
                          <a
                            class="nav-link border m-1 px-1 txt-num"
                            id="tab-700"
                            data-toggle="pill"
                            href="#pills-700"
                            role="tab"
                            aria-controls="pills-700"
                            aria-selected="false"
                            >700</a
                          >
                        </li>
                        <li class="nav-item flex-fill text-center">
                          <a
                            class="nav-link border m-1 px-1 txt-num"
                            id="tab-800"
                            data-toggle="pill"
                            href="#pills-800"
                            role="tab"
                            aria-controls="pills-800"
                            aria-selected="false"
                            >800</a
                          >
                        </li>
                        <li class="nav-item flex-fill text-center">
                          <a
                            class="nav-link border m-1 px-1 txt-num"
                            id="tab-900"
                            data-toggle="pill"
                            href="#pills-900"
                            role="tab"
                            aria-controls="pills-900"
                            aria-selected="false"
                            >900</a
                          >
                        </li>
                      </ul>
                      <div class="tab-content" id="numlist-tabContent1">
                        <div
                          class="text-center tab-pane fade show active"
                          id="pills-000"
                          role="tabpanel"
                          aria-labelledby="pills-000-tab"
                        >
                          <div
                            class="btn-group-toggle column d-inline"
                            v-for="(number, index) in numberSlot[0]"
                            :key="index"
                          >
                            <button
                              class="btn btn-sm mb-1 panghuay_number"
                              :disabled="isDisabledNumber(number)"
                              :class="[
                                limitButton(number) ? 'btn-yellow' : 'btn-limitnum',
                                { active: activeButton(number) }
                              ]"
                              @click="reverseThreeTab ? pushNumberSlotPermute(number) : pushNumberSlot(number)"
                            >
                              {{ number }}
                            </button>
                          </div>
                        </div>
                        <div
                          class="text-center tab-pane fade"
                          id="pills-100"
                          role="tabpanel"
                          aria-labelledby="pills-100-tab"
                        >
                          <div
                            class="btn-group-toggle column d-inline"
                            v-for="(number, index) in numberSlot[1]"
                            :key="index"
                          >
                            <button
                              class="btn btn-sm mb-1 panghuay_number"
                              :disabled="isDisabledNumber(number)"
                              :class="[
                                 limitButton(number) ? 'btn-yellow' : 'btn-limitnum',
                                { active: activeButton(number) }
                              ]"
                              @click="reverseThreeTab ? pushNumberSlotPermute(number) : pushNumberSlot(number)"
                            >
                              {{ number }}
                            </button>
                          </div>
                        </div>
                        <div
                          class="text-center tab-pane fade"
                          id="pills-200"
                          role="tabpanel"
                          aria-labelledby="pills-200-tab"
                        >
                          <div
                            class="btn-group-toggle column d-inline"
                            v-for="(number, index) in numberSlot[2]"
                            :key="index"
                          >
                            <button
                              class="btn btn-sm mb-1 panghuay_number"
                              :disabled="isDisabledNumber(number)"
                              :class="[
                                 limitButton(number) ? 'btn-yellow' : 'btn-limitnum',
                                { active: activeButton(number) }
                              ]"
                              @click="reverseThreeTab ? pushNumberSlotPermute(number) : pushNumberSlot(number)"
                            >
                              {{ number }}
                            </button>
                          </div>
                        </div>
                        <div
                          class="text-center tab-pane fade"
                          id="pills-300"
                          role="tabpanel"
                          aria-labelledby="pills-300-tab"
                        >
                          <div
                            class="btn-group-toggle column d-inline"
                            v-for="(number, index) in numberSlot[3]"
                            :key="index"
                          >
                            <button
                              class="btn btn-sm mb-1 panghuay_number"
                              :disabled="isDisabledNumber(number)"
                              :class="[
                                limitButton(number) ? 'btn-yellow' : 'btn-limitnum',
                                { active: activeButton(number) }
                              ]"
                              @click="reverseThreeTab ? pushNumberSlotPermute(number) : pushNumberSlot(number)"
                            >
                              {{ number }}
                            </button>
                          </div>
                        </div>
                        <div
                          class="text-center tab-pane fade"
                          id="pills-400"
                          role="tabpanel"
                          aria-labelledby="pills-400-tab"
                        >
                          <div
                            class="btn-group-toggle column d-inline"
                            v-for="(number, index) in numberSlot[4]"
                            :key="index"
                          >
                            <button
                              class="btn btn-sm mb-1 panghuay_number"
                              :disabled="isDisabledNumber(number)"
                              :class="[
                                 limitButton(number) ? 'btn-yellow' : 'btn-limitnum',
                                { active: activeButton(number) }
                              ]"
                              @click="reverseThreeTab ? pushNumberSlotPermute(number) : pushNumberSlot(number)"
                            >
                              {{ number }}
                            </button>
                          </div>
                        </div>
                        <div
                          class="text-center tab-pane fade"
                          id="pills-500"
                          role="tabpanel"
                          aria-labelledby="pills-500-tab"
                        >
                          <div
                            class="btn-group-toggle column d-inline"
                            v-for="(number, index) in numberSlot[5]"
                            :key="index"
                          >
                            <button
                              class="btn btn-sm mb-1 panghuay_number"
                              :disabled="isDisabledNumber(number)"
                              :class="[
                               limitButton(number) ? 'btn-yellow' : 'btn-limitnum',
                                { active: activeButton(number) }
                              ]"
                              @click="reverseThreeTab ? pushNumberSlotPermute(number) : pushNumberSlot(number)"
                            >
                              {{ number }}
                            </button>
                          </div>
                        </div>
                        <div
                          class="text-center tab-pane fade"
                          id="pills-600"
                          role="tabpanel"
                          aria-labelledby="pills-600-tab"
                        >
                          <div
                            class="btn-group-toggle column d-inline"
                            v-for="(number, index) in numberSlot[6]"
                            :key="index"
                          >
                            <button
                              class="btn btn-sm mb-1 panghuay_number"
                              :disabled="isDisabledNumber(number)"
                              :class="[
                                limitButton(number) ? 'btn-yellow' : 'btn-limitnum',
                                { active: activeButton(number) }
                              ]"
                              @click="reverseThreeTab ? pushNumberSlotPermute(number) : pushNumberSlot(number)"
                            >
                              {{ number }}
                            </button>
                          </div>
                        </div>
                        <div
                          class="text-center tab-pane fade"
                          id="pills-700"
                          role="tabpanel"
                          aria-labelledby="pills-700-tab"
                        >
                          <div
                            class="btn-group-toggle column d-inline"
                            v-for="(number, index) in numberSlot[7]"
                            :key="index"
                          >
                            <button
                              class="btn btn-sm mb-1 panghuay_number"
                              :disabled="isDisabledNumber(number)"
                              :class="[
                                 limitButton(number) ? 'btn-yellow' : 'btn-limitnum',
                                { active: activeButton(number) }
                              ]"
                              @click="reverseThreeTab ? pushNumberSlotPermute(number) : pushNumberSlot(number)"
                            >
                              {{ number }}
                            </button>
                          </div>
                        </div>
                        <div
                          class="text-center tab-pane fade"
                          id="pills-800"
                          role="tabpanel"
                          aria-labelledby="pills-800-tab"
                        >
                          <div
                            class="btn-group-toggle column d-inline"
                            v-for="(number, index) in numberSlot[8]"
                            :key="index"
                          >
                            <button
                              class="btn btn-sm mb-1 panghuay_number"
                              :disabled="isDisabledNumber(number)"
                              :class="[
                                 limitButton(number) ? 'btn-yellow' : 'btn-limitnum',
                                { active: activeButton(number) }
                              ]"
                              @click="reverseThreeTab ? pushNumberSlotPermute(number) : pushNumberSlot(number)"
                            >
                              {{ number }}
                            </button>
                          </div>
                        </div>
                        <div
                          class="text-center tab-pane fade"
                          id="pills-900"
                          role="tabpanel"
                          aria-labelledby="pills-900-tab"
                        >
                          <div
                            class="btn-group-toggle column d-inline"
                            v-for="(number, index) in numberSlot[9]"
                            :key="index"
                          >
                            <button
                              class="btn btn-sm mb-1 panghuay_number"
                              :disabled="isDisabledNumber(number)"
                              :class="[
                                 limitButton(number) ? 'btn-yellow' : 'btn-limitnum',
                                { active: activeButton(number) }
                              ]"
                              @click="reverseThreeTab ? pushNumberSlotPermute(number) : pushNumberSlot(number)"
                            >
                              {{ number }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="pills-2" role="tabpanel" aria-labelledby="pills-2-tab">
                    <div class="row-btn-tanghuay">
                      <button
                        class="btn btn-grey btn-block btn-sm btn-panghuay py-2"
                        :class="{ active: reverseTwoTab }"
                        id="shuffle_3"
                        @click="reverseTwoTab = !reverseTwoTab"
                      >
                        <i class="fas fa-random"></i> กลับตัวเลข
                      </button>
                    </div>
                    <div class="row m-0">
                      <div
                        class="col-6 p-1 size-button"
                        v-for="(item, index) in getInquiryRoundInfomation.my_price.filter(e => e.nlen === 2)"
                        :key="index"
                      >
                        <div class="row-btn-tanghuay">
                          <button
                            class="
                              btn btn-outline-blue btn-sm btn-block
                              h-100
                              d-flex
                              justify-content-between
                              align-items-center
                              btn-panghuay
                              bet_two
                            "
                            id="teng_bon_2"
                            :class="[
                              listSelector.find(e => e.index === item.index) ? 'active' : '',
                              item.nlen === 2
                                ? 'btn-outline-blue'
                                : item.nlen === 1
                                ? 'btn-outline-green'
                                : 'btn-outline-red'
                            ]"
                            @click="selectedByIndex(item.index)"
                          >
                            <div class="bg-primary text-white flex-fill">
                              <i>{{ item.title }}</i>
                            </div>
                            <div class="text-center mx-1">
                              {{ item.price }}
                            </div>
                          </button>
                        </div>
                      </div>

                      <div class="col-12 col-sm-12 col-md-12 col-lg-4 pt-1 pb-0 px-0">
                        <div
                          id="ninetybtn"
                          v-bind:class="[listSelector.filter(v => v.nlen === 2).length > 0 ? '' : 'overlay-disable']"
                        ></div>
                        <span class="d-block d-sm-block d-md-inline">19ประตู</span>
                        <div class="btn-group btn-group-sm d-flex" role="group">
                          <div
                            class="btn-group-toggle d-inline flex-fill"
                            data-toggle="buttons"
                            v-for="index in 10"
                            :key="index"
                          >
                            <label
                              class="btn btn-outline-secondary btn-rood btn-sm mb-1 panghuay_option_2"
                              :id="`01${index - 1}`"
                              data-option="option_2_19"
                              @change="pushNumberSlotArray('01', index - 1)"
                            >
                              <input
                                type="checkbox"
                                autocomplete="off"
                                name="ninety"
                                :id="`01${index - 1}`"
                                :value="index - 1"
                              />
                              {{ index - 1 }}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-12 col-md-12 col-lg-4 pt-1 pb-0 px-0">
                        <div
                          id="ninetybtn"
                          v-bind:class="[listSelector.filter(v => v.nlen === 2).length > 0 ? '' : 'overlay-disable']"
                        ></div>
                        <span class="d-block d-sm-block d-md-inline">รูดหน้า</span>
                        <div class="btn-group btn-group-sm d-flex" role="group">
                          <div
                            class="btn-group-toggle d-inline flex-fill"
                            data-toggle="buttons"
                            v-for="index in 10"
                            :key="index"
                          >
                            <label
                              class="btn btn-outline-secondary btn-rood btn-sm mb-1 panghuay_option_2"
                              :id="`02${index - 1}`"
                              data-option="option_2_19"
                              @change="pushNumberSlotArray('02', index - 1)"
                            >
                              <input
                                type="checkbox"
                                autocomplete="off"
                                name="ninety"
                                :id="`02${index - 1}`"
                                :value="index - 1"
                              />
                              {{ index - 1 }}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="col-12 col-sm-12 col-md-12 col-lg-4 pt-1 pb-0 px-0">
                        <div
                          id="ninetybtn"
                          v-bind:class="[listSelector.filter(v => v.nlen === 2).length > 0 ? '' : 'overlay-disable']"
                        ></div>
                        <span class="d-block d-sm-block d-md-inline">รูดหลัง</span>
                        <div class="btn-group btn-group-sm d-flex" role="group">
                          <div
                            class="btn-group-toggle d-inline flex-fill"
                            data-toggle="buttons"
                            v-for="index in 10"
                            :key="index"
                          >
                            <label
                              class="btn btn-outline-secondary btn-rood btn-sm mb-1 panghuay_option_2"
                              :id="`03${index - 1}`"
                              data-option="option_2_19"
                              @change="pushNumberSlotArray('03', index - 1)"
                            >
                              <input
                                type="checkbox"
                                autocomplete="off"
                                name="ninety"
                                :id="`03${index - 1}`"
                                :value="index - 1"
                              />
                              {{ index - 1 }}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div class="w-100 border-top my-1"></div>
                      <div class="col-12 text-center px-0 py-1" id="numlist-tabContent2">
                        <div
                          id="twonumberbtn"
                          v-bind:class="[listSelector.filter(v => v.nlen === 2).length > 0 ? '' : 'overlay-disable']"
                        ></div>
                        <div
                          class="btn-group-toggle column d-inline"
                          v-for="(number, index) in numberTwoSlot"
                          :key="index"
                        >
                          <button
                            class="btn btn-sm mb-1 panghuay_number"
                            :disabled="isDisabledNumber(number)"
                            :class="[
                              limitButton(number) ? 'btn-yellow' : 'btn-limitnum',
                              { active: activeButton(number) }
                            ]"
                            @click="reverseTwoTab ? pushNumberSlotPermute(number) : pushNumberSlot(number)"
                          >
                            {{ number }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="tab-pane fade" id="pills-run" role="tabpanel" aria-labelledby="pills-run-tab">
                    <div class="row m-0">
                      <div
                        class="col-6 p-1 size-button"
                        v-for="(item, index) in getInquiryRoundInfomation.my_price.filter(e => e.nlen === 1)"
                        :key="index"
                      >
                        <div class="row-btn-tanghuay">
                          <button
                            class="
                              btn btn-outline-green btn-sm btn-block
                              h-100
                              d-flex
                              justify-content-between
                              align-items-center
                              btn-panghuay
                              bet_run
                            "
                            id="teng_bon_1"
                            v-bind:class="[
                              listSelector.find(e => e.index === item.index) ? 'active' : '',
                              item.nlen === 2
                                ? 'btn-outline-blue'
                                : item.nlen === 1
                                ? 'btn-outline-green'
                                : 'btn-outline-red'
                            ]"
                            @click="selectedByIndex(item.index)"
                          >
                            <div class="bg-success text-white flex-fill">
                              <i>{{ item.title }}</i>
                            </div>
                            <div class="text-center mx-1">
                              {{ item.price }}
                            </div>
                          </button>
                        </div>
                      </div>
                      <div id="numlist-tabContent3" class="col-12 p-1 d-flex flex-wrap flex-row justify-content-center">
                        <div
                          id="runnumberbtn"
                          v-bind:class="[listSelector.filter(v => v.nlen === 1).length > 0 ? '' : 'overlay-disable']"
                        ></div>
                        <div class="btn-group-toggle" v-for="(number, index) in numberSingleSlot" :key="index">
                          <button
                            class="btn btn-block txt-num btn-sm mb-1 panghuay_number"
                            :disabled="isDisabledNumber(number)"
                            :class="[
                               limitButton(number) ? 'btn-yellow' : 'btn-limitnum',
                              { active: activeButton(number) }
                            ]"
                            @click="pushNumberSlot(number)"
                          >
                            {{ number }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b></b
            >
          </div>
          <b><b> </b></b>
        </div>
        <b><b> </b></b>
      </div>
      <b>
        <div class="w-100">
          <div class="row mb-2 py-3 mx-0 bg-dark rounded">
            <div class="col pr-1">
              <button
                class="btn btn-light btn-sm btn-block number-sets border triggerPoy"
                @click="$emit('openSet', true)"
              >
                <i class="fas fa-list-ol"></i> ดึงโพย
              </button>
            </div>
            <div class="col pl-1">
              <button
                :disabled="getNumberList.length === 0"
                class="btn btn-light btn-sm btn-block show-lists triggerPrice"
                @click="openPrice()"
              >
                <i class="fas fa-edit"></i> ใส่ราคา
              </button>
            </div>
          </div>
        </div>
        <app-condition :listSelector="listSelector" />
      </b>
    </div>
    <b><b> </b></b>
  </div>
</template>

<script>
import condition from '@/components/lottocomp/condition'
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    numberSets: {
      type: Array,
      default: () => []
    }
  },
  components: {
    'app-condition': condition
  },
  watch: {
    listSelector: function (val) {
      if (val.length === 0 || val.find(x => x.nlen !== 2)) {
        this.subType = undefined
      }
    },
    numberSets: function (val) {
      if (!val?.length) {
        return
      }
      val.map(e => {
        e.numbers.map(s => {
          this.pushNumberSlotByTile(s, e.key)
        })
      })
      this.setNumberList(this.numberList)
    },
    tabMode: function () {
      this.reverseThreeTab = false
      this.reverseTwoTab = false
    }
  },
  async created () {
    try {
      this.setNumberList([])
      this.setLoading(true)
      await this.getInquiryRoundInfomationByID(this.$route.params.id)
      this.result.roundID = this.$route.params.id
      this.setLoading(false)
      this.listSelector = [this.getInquiryRoundInfomation.my_price[0]]
      this.type = this.getInquiryRoundInfomation.my_price[0].nlen
      this.limits = this.getInquiryRoundInfomation.price_limit
      if (this.limits.length > 0) {
        this.limits.forEach((e, index) => {
          if (this.slimits[e.index]) {
            this.slimits[Number(e.index)][Number(e.num)] = e.status
          } else {
            this.slimits[e.index] = []
            this.slimits[Number(e.index)][Number(e.num)] = e.status
          }
        })
      }
      if (this.getInquiryRoundInfomation.title !== '') {
        if (Number(this.getInquiryRoundInfomation.status) !== 1) {
          this.$router.push('/member/lottery/lotto/result/' + this.$route.params.id)
        }
      }
    } catch (error) {
      this.setLoading(false)
    }
  },
  mounted () {
    this.setDefaultThreeNumber()
    this.setDefaultTwoNumber()
    this.setDefaultNumber()
  },
  data: function () {
    return {
      limits: [],
      slimits: [[], [], [], [], [], [], [], [], []],
      number: '',
      numberList: [],
      listSelector: [],
      numberSlot: [],
      numberTwoSlot: [],
      numberSingleSlot: [],
      subTypeNum: '',
      type: 3,
      result: {
        roundID: null,
        type: [],
        number: [],
        price: []
      },
      isHide: false,
      numberTypeRoot: [],
      numberTypeStartRoot: [],
      numberTypeEndRoot: [],
      subType: undefined,
      reserveTwoNumber: false,
      reserveThreeNumber: false,
      permArr: [],
      usedChars: [],
      reverseThreeTab: false,
      reverseTwoTab: false,
      tabMode: 1
    }
  },
  methods: {
    ...mapActions([
      'setRoundId',
      'getInquiryRoundInfomationByID',
      'setLoading',
      'setNumber Set',
      'setNumberList',
      'getBalanceInfo'
    ]),
    resultReport () {
      return this.getNumberList.reduce((a, e) => a + e.list.length, 0)
    },
    setDefaultThreeNumber () {
      let old = 0
      const data = [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]
      data.forEach(e => {
        const numberSet = []
        for (let index = old; index < e; index++) {
          const str = '' + index
          const pad = '000'
          const ans = pad.substring(0, pad.length - str.length) + str
          numberSet.push(ans)
        }
        this.numberSlot.push(numberSet)
        old = e
      })
    },
    setDefaultTwoNumber () {
      const numberSet = []
      for (let index = 0; index < 100; index++) {
        const str = '' + index
        const pad = '00'
        const ans = pad.substring(0, pad.length - str.length) + str
        numberSet.push(ans)
      }
      this.numberTwoSlot = numberSet
    },
    setDefaultNumber () {
      const numberSet = []
      for (let index = 0; index < 10; index++) {
        const str = '' + index
        const pad = '0'
        const ans = pad.substring(0, pad.length - str.length) + str
        numberSet.push(ans)
      }
      this.numberSingleSlot = numberSet
    },
    selectedByIndex (index) {
      const result = this.getInquiryRoundInfomation.my_price.find(e => e.index === index)
      if (result?.nlen !== this.type) {
        this.type = result?.nlen
        this.listSelector = [result]
        this.clear()
      } else {
        if (this.listSelector.find(e => e.index === index)) {
          const indexItem = this.listSelector.indexOf(result)
          if (indexItem !== -1) {
            this.listSelector.splice(indexItem, 1)
          }
        } else {
          this.listSelector.push(result)
        }
      }
      if (result.nlen === 3 && this.reserveTwoNumber) {
        this.reserveTwoNumber = !this.reserveTwoNumber
      } else if (result.nlen === 2 && this.reserveThreeNumber) {
        this.reserveThreeNumber = !this.reserveThreeNumber
      }
    },
    deleteLast () {
      this.number = this.number.substring(0, this.number.length - 1)
    },
    permute (input) {
      let i
      let ch
      for (i = 0; i < input.length; i++) {
        ch = input.splice(i, 1)[0]
        this.usedChars.push(ch)
        if (input.length === 0) {
          this.permArr.push(this.usedChars.slice())
        }
        this.permute(input)
        input.splice(i, 0, ch)
        this.usedChars.pop()
      }
      return this.permArr
    },
    runPermute (input) {
      const result = this.permute(input)
      this.permArr = []
      this.usedChars = []
      return this.$_.uniq(result.map(e => e.join('')))
    },
    pushNumberSlotPermute (number) {
      const newNumbers = this.runPermute(number.split(''))
      newNumbers.forEach(e => {
        this.pushNumberSlot(e)
      })
    },
    pushNumber (numbers) {
      this.numberList = this.getNumberList
      this.number = this.number + numbers
      if (this.number.split('').length > this.type - 1) {
        this.listSelector.map(e => {
          if (this.numberList.find(d => d.title === e.title)) {
            if (this.listSelector.find(d => d.title === e.title).nlen === 3 && this.reserveThreeNumber) {
              const newReverse = this.runPermute(this.number.split(''))
              newReverse.map(num => this.numberList.find(d => d.title === e.title).list.push(num))
            } else if (this.listSelector.find(d => d.title === e.title).nlen === 2 && this.reserveTwoNumber) {
              const newReverse = this.runPermute(this.number.split(''))
              newReverse.map(num => this.numberList.find(d => d.title === e.title).list.push(num))
            } else {
              this.numberList.find(d => d.title === e.title).list.push(this.number)
            }
          } else {
            let reserveNumber
            if (e.nlen === 3 && this.reserveThreeNumber) {
              const newReverse = this.runPermute(this.number.split(''))
              reserveNumber = newReverse
            } else if (e.nlen === 2 && this.reserveTwoNumber) {
              const newReverse = this.runPermute(this.number.split(''))
              reserveNumber = newReverse
            }
            this.numberList.push({
              title: e.title,
              data: e,
              list: reserveNumber ? [...reserveNumber] : [this.number]
            })
          }
        })
        this.numberList.forEach(e => {
          e.list = this.removeDuplicates(e.list)
        })
        this.setNumberList(this.numberList)
        this.clear()
      }
    },
    removeDuplicates (arr) {
      return [...new Set(arr)]
    },
    pushNumberSlot (number) {
      this.listSelector.map(e => {
        const finding = this.numberList.find(d => d.title === e.title)
        if (finding) {
          if (finding.list.find(v => v === number)) {
            let index = finding.list.indexOf(number)
            if (index > -1) {
              while (index !== -1) {
                finding.list.splice(index, 1)
                index = finding.list.indexOf(number)
              }
            }
            if (finding.list.length === 0) {
              this.$_.remove(this.numberList, el => el.title === finding.title)
            }
          } else {
            if (finding.list.indexOf(String(number)) === -1) {
              finding.list.push(number)
            }
          }
        } else {
          this.numberList.push({
            title: e.title,
            data: e,
            list: [number]
          })
        }
      })
      this.setNumberList(this.numberList)
    },
    pushNumberSlotByTile (number, title) {
      this.getInquiryRoundInfomation.my_price.filter(e => e.title === title && e.enable).map(e => {
        const finding = this.numberList.find(d => d.title === e.title)
        if (finding) {
          if (finding.list.find(v => v === number)) {
            let index = finding.list.indexOf(number)
            if (index > -1) {
              while (index !== -1) {
                finding.list.splice(index, 1)
                index = finding.list.indexOf(number)
              }
            }
            if (finding.list.length === 0) {
              this.$_.remove(this.numberList, el => el.title === finding.title)
            }
          } else {
            if (finding.list.indexOf(String(number)) === -1) {
              finding.list.push(number)
            }
          }
        } else {
          this.numberList.push({
            title: e.title,
            data: e,
            list: [number]
          })
        }
      })
      this.setNumberList(this.numberList)
    },
    pushNumberSlotTwo (number, isDeleted) {
      this.listSelector.map(e => {
        const finding = this.numberList.find(d => d.title === e.title)
        if (finding) {
          if (isDeleted) {
            const index = finding.list.indexOf(number.toString())
            if (index !== -1) {
              finding.list.splice(index, 1)
              if (finding.list.length === 0) {
                this.$_.remove(this.numberList, el => el.title === finding.title)
              }
            }
          } else {
            finding.list.push(number)
          }
        } else {
          this.numberList.push({
            title: e.title,
            data: e,
            list: [number]
          })
        }
      })
      this.setNumberList(this.numberList)
    },
    pushNumberSlotArray (slotId, number) {
      this.reverseTwoTab = false
      let data = []
      let isDeleted = false
      switch (slotId) {
        case '01':
          data = this.numberTwoSlot.filter(e => e.includes(`${number}`))
          if (this.numberTypeRoot.indexOf(`${number}`) === -1) {
            this.numberTypeRoot.push(number.toString())
            isDeleted = false
          } else {
            const index = this.numberTypeRoot.indexOf(`${number}`)
            this.numberTypeRoot.splice(index, 1)
            isDeleted = true
          }
          break
        case '02':
          data = this.numberTwoSlot.filter(e => e.startsWith(`${number}`))
          if (this.numberTypeStartRoot.indexOf(`${number}`) === -1) {
            this.numberTypeStartRoot.push(number.toString())
            isDeleted = false
          } else {
            const index = this.numberTypeStartRoot.indexOf(`${number}`)
            this.numberTypeStartRoot.splice(index, 1)
            isDeleted = true
          }
          break
        default:
          data = this.numberTwoSlot.filter(e => e.endsWith(`${number}`))
          if (this.numberTypeEndRoot.indexOf(`${number}`) === -1) {
            this.numberTypeEndRoot.push(number.toString())
            isDeleted = false
          } else {
            const index = this.numberTypeEndRoot.indexOf(`${number}`)
            this.numberTypeEndRoot.splice(index, 1)
            isDeleted = true
          }
          break
      }
      data.map(num => {
        this.pushNumberSlotTwo(num, isDeleted)
      })
    },
    clear () {
      this.number = ''
    },
    clearList () {
      this.numberList = []
      this.setNumberList(this.numberList)
    },
    removeLast () {
      this.numberList.splice(-1, 1)
      this.setNumberList(this.numberList)
    },
    openPrice () {
      this.setRoundId(parseInt(this.$route.params.id))
      this.setNumberList(this.numberList)
      this.$emit('openPrice', true)
    },
    hideTab (value) {
      this.isHide = value
    },
    activeButton (number) {
      const data = this.getNumberList.map(e => e.list || [])
      if (data && data.length) {
        const flatternData = this.$_.flatten(data)
        return flatternData.indexOf(number) !== -1
      } else {
        return false
      }
    },
    limitButton (number) {
      if (!this.limits?.length) {
        return false
      }
      let status = false
      this.listSelector.map(e => {
        if (this.slimits[Number(e.index)][Number(number)]) {
          if (Number(this.slimits[Number(e.index)][Number(number)]) === 1) {
            status = true
          }
        }
      })
      return status
    },
    isHighLighActiveByType (typeList) {
      return this.listSelector.filter(e => ((Number(e.index) === Number(typeList)))).length > 0
    },
    isDisabledNumber (number) {
      if (!this.limits?.length) {
        return false
      }
      let status = false
      this.listSelector.map(e => {
        if (this.slimits[Number(e.index)][Number(number)]) {
          if (Number(this.slimits[Number(e.index)][Number(number)]) === 0) {
            status = true
          }
        }
      })
      return status
    },
    oddOrEvenNumber (isEven) {
      this.listSelector.map(e => {
        if (this.numberList.find(d => d.title === e.title)) {
          for (let i = 0; i < 100; i++) {
            if (isEven) {
              if (i % 2 === 0) {
                const str = '' + i
                const pad = '00'
                const ans = pad.substring(0, pad.length - str.length) + str
                this.numberList.find(d => d.title === e.title).list.push(String(ans))
              }
            } else {
              if (i % 2 !== 0) {
                const str = '' + i
                const pad = '00'
                const ans = pad.substring(0, pad.length - str.length) + str
                this.numberList.find(d => d.title === e.title).list.push(String(ans))
              }
            }
          }
        } else {
          const numbers = []
          for (let i = 0; i < 100; i++) {
            if (isEven) {
              if (i % 2 === 0) {
                const str = '' + i
                const pad = '00'
                const ans = pad.substring(0, pad.length - str.length) + str
                numbers.push(String(ans))
              }
            } else {
              if (i % 2 !== 0) {
                const str = '' + i
                const pad = '00'
                const ans = pad.substring(0, pad.length - str.length) + str
                numbers.push(String(ans))
              }
            }
          }
          this.numberList.push({
            title: e.title,
            data: e,
            list: numbers
          })
        }
      })
      this.setNumberList(this.numberList)
    },
    lowOrHighPushNumber (isHigh) {
      const start = isHigh ? 50 : 0
      const end = isHigh ? 100 : 50
      this.listSelector.map(e => {
        if (this.numberList.find(d => d.title === e.title)) {
          for (let i = start; i < end; i++) {
            const str = '' + i
            const pad = '00'
            const ans = pad.substring(0, pad.length - str.length) + str
            this.numberList.find(d => d.title === e.title).list.push(String(ans))
          }
        } else {
          const numbers = []
          for (let i = start; i < end; i++) {
            const str = '' + i
            const pad = '00'
            const ans = pad.substring(0, pad.length - str.length) + str
            numbers.push(String(ans))
          }
          this.numberList.push({
            title: e.title,
            data: e,
            list: numbers
          })
        }
      })
      this.setNumberList(this.numberList)
    },
    doublePushNumber () {
      const doubleNumber = ['00', '11', '22', '33', '44', '55', '66', '77', '88', '99']
      this.listSelector.map(e => {
        if (this.numberList.find(d => d.title === e.title)) {
          doubleNumber.map(num => this.numberList.find(d => d.title === e.title).list.push(num))
        } else {
          this.numberList.push({
            title: e.title,
            data: e,
            list: doubleNumber
          })
        }
      })
      this.setNumberList(this.numberList)
    },
    setSubType (type) {
      this.subType = this.subType === type ? undefined : type
    },
    setPushNumberSetRoot (number) {
      // let data = []
      this.subTypeNum = String(number)
      switch (this.subType) {
        case 'รูดหน้า':
          this.pushNumberSlotArray('02', number)
          // data = this.numberTwoSlot.filter(e => e.startsWith(String(number)))
          break
        case 'รูดหลัง':
          this.pushNumberSlotArray('03', number)
          // data = this.numberTwoSlot.filter(e => e.endsWith(String(number)))
          break
        default:
          this.pushNumberSlotArray('01', number)
          break
      }
      this.subTypeNum = ''
      // data.map(num => this.pushNumberSlot(num))
    },
    setReverseNumber (twoOrThree) {
      if (twoOrThree) {
        const result = this.listSelector.find(e => e.nlen === 2)
        if (result?.nlen !== this.type) {
          this.type = 0
          this.listSelector = []
          this.clear()
        }
        this.reserveTwoNumber = !this.reserveTwoNumber
        this.reserveThreeNumber = false
      } else {
        const result = this.listSelector.find(e => e.nlen === 3)
        if (result?.nlen !== this.type) {
          this.type = 0
          this.listSelector = []
          this.clear()
        }
        this.reserveThreeNumber = !this.reserveThreeNumber
        this.reserveTwoNumber = false
      }
    }
  },
  computed: {
    ...mapGetters(['getInquiryRoundInfomation', 'getLotteryList', 'getNumberList'])
  }
}
</script>

<style scoped>
.hide-tab {
  min-width: 0 !important;
  width: 0 !important;
  opacity: 0 !important;
}
.hide-tab2 {
  display: none !important;
}
.hide-tab3 {
  padding: 0 !important;
  min-width: 0 !important;
  width: 0 !important;
  opacity: 0 !important;
  overflow: hidden !important;
}
.show-tab {
  width: 0 !important;
}
.btn-width {
  width: 80%;
  margin: 10px;
}
</style>
