<template>
  <div
    class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-0 pb-0"
  >
    <div
      class="d-flex justify-content-between align-items-center mb-0 flex-fill lotto-title"
    >
      <h4 class="tanghuay-h4">
        {{ getInquiryRoundInfomation.group_name!="" ? getInquiryRoundInfomation.group_name : "" }}
        <span v-if="getInquiryRoundInfomation.group_name!=''" class="badge badge-pill badge-primary font-weight-light">
          <small> {{ getInquiryRoundInfomation.title ? getInquiryRoundInfomation.title : "" }}</small>
        </span>
      </h4>
      <div class="tanghuay-time">
        <i class="sn-icon sn-icon--daily2"></i>
        <span
          class="countdown"
          id="offset"
          data-finaldate="1610871600000"
          v-html="getInquiryRoundInfomation.date.date_close != null ? calTime(getInquiryRoundInfomation.date.date_close) : ''"
        >
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import { flatten } from 'lodash'
import moment from 'moment'
export default {
  async created () {
    setInterval(() => {
      this.getNow()
    }, 1000)
    // await this.getLottoInter()
    // const mapdata = this.getLotteryList.map(e => e.list)
    // TOP this.dataInfo = flatten(mapdata).find(
    //   (e) => e.LottoCatagory.lottoId === this.$route.params.id
    // )
    // this.titleLottoCatagory = getInquiryRoundInfomation.group_name
    // this.dataInfo.titleLottoCatagory = 'ttttt'
    // this.setTitleBet(this.dataInfo.titleLottoCatagory)
    // await this.getInquiryRoundInfomationByID(this.$route.params.id)
  },
  methods: {
    ...mapActions(['getInquiryRoundInfomationByID', 'setTitleBet']),
    calTime (value) {
      const final = moment(value)
      const now = this.currentDate ? this.currentDate : ''
      const timeleft = final - now
      if (timeleft > 0) {
        const days = Math.floor(timeleft / (1000 * 60 * 60 * 24))
        const hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((timeleft % (1000 * 60)) / 1000)
        if (days > 0) {
          return `${days}<small>วัน</small> ${hours}<small>:</small>${minutes}<small>:</small>${seconds}`
        } else {
          return `${hours}<small>:</small>${minutes}<small>:</small>${seconds}`
        }
      } else {
        return '00<small>:</small>00<small>:</small>00'
      }
    },
    getNow () {
      this.currentDate = moment()
    }
  },
  data: () => {
    return {
      titleLottoCatagory: '',
      dataInfo: {},
      currentDate: ''
    }
  },
  computed: {
    ...mapGetters(['getInquiryRoundInfomation'])
  }
}
</script>

<style>
</style>
